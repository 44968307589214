import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 991.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
          transform="translate(0.000000,991.000000) scale(0.100000,-0.100000)"
        >
          <path
            d="M5590 8429 c-333 -29 -652 -125 -1005 -301 -127 -64 -487 -283 -505
-307 -3 -4 -27 -23 -55 -41 -27 -19 -55 -39 -62 -45 -7 -5 -73 -59 -147 -120
-173 -141 -193 -174 -46 -75 176 118 490 292 680 376 393 173 715 253 1026
254 196 0 428 -36 539 -85 17 -7 48 -21 70 -30 123 -54 255 -170 332 -293 44
-69 88 -192 98 -274 11 -86 26 -61 60 100 20 98 20 287 -1 371 -35 140 -114
256 -224 327 -76 49 -118 67 -221 99 -130 39 -370 59 -539 44z"
          />
          <path
            d="M5348 7955 c-117 -15 -148 -22 -148 -35 0 -6 20 -10 44 -10 152 0
355 -102 445 -223 105 -142 136 -261 128 -490 -9 -254 -70 -473 -222 -792 -64
-136 -218 -408 -245 -435 -3 -3 -18 -25 -35 -50 -30 -45 -150 -209 -209 -285
-136 -175 -471 -527 -660 -691 -634 -555 -1364 -899 -1846 -870 -320 19 -527
174 -602 453 -16 62 -21 107 -21 223 0 164 16 270 67 443 27 91 15 99 -26 16
-190 -386 -279 -708 -281 -1019 -1 -195 23 -312 89 -440 210 -406 728 -490
1429 -233 171 62 482 213 615 298 19 12 85 53 145 90 61 38 121 77 135 88 14
10 64 46 111 80 139 100 343 261 424 335 22 20 57 52 78 70 l38 32 198 0 198
0 39 43 c21 23 77 85 124 137 47 52 106 118 131 145 26 28 62 68 80 90 19 22
36 42 39 45 7 6 106 115 173 189 26 29 44 57 41 63 -5 8 -59 9 -170 5 -99 -4
-165 -3 -169 3 -3 5 27 53 68 107 97 129 207 283 207 289 0 3 18 32 40 64 22
32 73 115 113 184 39 68 82 142 95 163 l23 38 -5 -40 c-4 -39 -34 -210 -76
-445 -24 -129 -27 -146 -70 -390 -17 -96 -35 -200 -40 -230 -5 -30 -18 -102
-29 -160 -11 -58 -31 -172 -45 -255 -29 -161 -60 -336 -76 -425 -38 -203 -140
-782 -140 -793 0 -4 195 -6 433 -5 l432 3 97 575 c53 316 97 581 97 588 1 6 5
12 11 12 8 0 147 -138 265 -265 20 -22 121 -128 224 -235 283 -295 370 -387
457 -479 43 -46 106 -110 138 -142 l59 -59 644 0 c376 0 643 4 643 9 0 5 -15
22 -34 38 -53 44 -224 198 -391 353 -53 48 -197 178 -245 220 -9 8 -62 56
-116 106 -54 50 -121 110 -149 135 -27 24 -77 69 -110 100 -33 31 -82 76 -110
100 -27 24 -52 46 -55 49 -14 14 -174 159 -354 322 -22 19 -36 39 -33 45 3 5
48 36 99 69 51 32 163 104 248 159 204 133 582 379 645 420 95 61 360 234 520
338 88 58 228 149 310 202 170 110 160 103 153 110 -3 3 -281 5 -618 5 l-614
0 -37 -29 c-20 -16 -76 -60 -125 -96 -49 -36 -100 -74 -114 -85 -14 -10 -54
-40 -90 -67 -36 -26 -72 -53 -80 -60 -8 -7 -116 -87 -240 -180 -124 -92 -340
-252 -480 -357 -140 -104 -270 -200 -288 -214 -18 -13 -36 -21 -40 -18 -6 7
46 293 164 896 19 96 34 183 34 192 0 17 -27 18 -417 20 l-418 3 1 25 c1 14
26 81 56 150 143 323 207 594 208 866 0 264 -60 441 -199 587 -172 181 -446
255 -783 212z"
          />
          <path
            d="M3057 7257 c-16 -18 -63 -68 -105 -112 -43 -44 -134 -141 -203 -215
-121 -129 -176 -188 -276 -293 -27 -28 -42 -52 -37 -57 5 -5 442 -4 1012 2
684 7 1007 7 1014 0 7 -7 -20 -30 -83 -73 -52 -34 -128 -86 -169 -114 -41 -28
-138 -94 -215 -146 -77 -53 -212 -144 -300 -204 -88 -59 -200 -136 -250 -170
-49 -34 -184 -126 -300 -205 -115 -79 -239 -164 -275 -189 -36 -25 -137 -94
-225 -154 -263 -180 -325 -235 -387 -347 -92 -164 -44 -350 109 -429 73 -37
139 -41 670 -41 458 0 517 2 546 17 106 54 475 303 535 361 8 7 30 25 50 40
44 32 160 130 217 183 22 20 52 48 68 61 15 14 27 28 27 32 0 3 -168 6 -372 6
l-373 1 35 30 c19 16 125 91 235 165 110 75 265 180 345 235 80 55 177 121
215 146 39 25 72 49 75 52 3 3 79 56 170 117 184 124 510 348 525 361 6 4 22
16 38 26 54 35 212 388 273 612 28 103 54 243 54 298 l0 37 -1307 0 -1308 -1
-28 -32z"
          />
          <path
            d="M4687 3031 c-128 -9 -131 -15 -96 -191 27 -142 41 -471 29 -733 -9
-195 -16 -243 -54 -346 -17 -46 -21 -99 -8 -117 4 -6 54 -11 129 -11 106 0
124 2 139 18 14 15 14 23 4 51 -6 18 -14 94 -17 170 l-6 136 37 77 c22 45 41
74 46 69 21 -21 39 -135 45 -277 4 -83 11 -165 17 -181 l11 -28 74 7 c63 6 79
4 114 -14 23 -12 58 -21 85 -21 38 0 46 4 56 25 10 22 6 32 -27 82 -168 253
-294 518 -280 589 12 67 140 209 259 288 93 62 73 92 -84 126 -135 30 -132 30
-152 -10 -10 -19 -56 -114 -104 -210 -47 -96 -89 -179 -94 -183 -4 -5 -6 20
-4 55 2 34 5 151 8 258 3 166 7 205 26 259 11 35 20 75 18 90 -3 30 -20 32
-171 22z"
          />
          <path
            d="M2999 3011 c-23 -18 -23 -20 -11 -105 7 -48 10 -91 7 -96 -4 -6 -34
-10 -69 -10 -51 0 -66 4 -82 21 -25 28 -67 49 -97 49 -35 0 -103 -69 -111
-113 -15 -80 51 -153 129 -143 167 22 235 28 249 23 14 -6 16 -41 16 -326 0
-344 -6 -401 -54 -538 -47 -132 -45 -134 128 -139 130 -3 136 -2 151 19 20 29
19 65 -5 115 -32 66 -39 121 -56 455 -18 367 -12 564 22 661 19 52 18 111 -2
130 -24 25 -184 23 -215 -3z"
          />
          <path
            d="M8763 3018 c-31 -15 -34 -30 -22 -113 17 -110 18 -108 -104 -101 -56
3 -121 8 -144 12 -54 8 -62 -8 -60 -111 l1 -78 172 6 171 7 7 -238 c7 -255 -5
-479 -28 -552 -8 -25 -21 -63 -30 -85 -18 -47 -21 -111 -5 -121 6 -3 69 -8
140 -10 122 -3 129 -2 144 19 21 29 19 80 -5 126 -25 51 -38 158 -51 441 -16
324 -8 574 20 661 25 80 20 126 -16 140 -33 12 -163 10 -190 -3z"
          />
          <path
            d="M2060 2890 c-91 -3 -176 -11 -190 -17 -20 -8 -31 -27 -48 -83 -32
-97 -28 -105 51 -95 236 28 299 31 304 17 3 -7 3 -40 0 -72 -5 -62 -19 -92
-132 -285 -243 -419 -367 -601 -433 -636 -33 -18 -43 -41 -29 -69 17 -33 56
-41 180 -33 l108 6 93 186 c121 241 84 177 413 721 70 116 150 217 203 257 41
30 50 43 48 63 -3 23 -9 26 -83 37 -85 12 -216 13 -485 3z"
          />
          <path
            d="M3350 2831 l-114 -16 -8 -45 c-5 -25 -7 -61 -6 -80 l3 -34 95 -13
c172 -22 190 -16 190 63 0 25 7 61 15 81 29 69 20 72 -175 44z"
          />
          <path
            d="M9100 2832 c-58 -8 -106 -16 -108 -17 -11 -9 -21 -139 -12 -150 6 -7
37 -15 70 -19 33 -3 87 -9 120 -12 75 -8 90 2 90 65 1 25 7 64 15 86 8 22 15
46 15 53 0 15 -51 14 -190 -6z"
          />
          <path
            d="M4156 2544 c-109 -40 -169 -122 -170 -233 -1 -103 35 -164 163 -271
125 -104 161 -156 161 -227 0 -44 -6 -55 -37 -72 -31 -16 -51 -14 -84 10 -32
23 -39 67 -17 114 9 20 8 31 -2 50 -16 30 -52 32 -144 11 -87 -21 -94 -43 -47
-147 57 -126 126 -165 270 -157 103 6 162 35 211 103 34 47 35 53 35 135 0 67
-5 93 -22 125 -30 57 -90 115 -162 155 -136 77 -161 103 -161 171 0 41 48 89
88 89 16 0 40 -9 53 -19 21 -17 24 -27 22 -84 -1 -63 0 -66 28 -77 45 -19 97
-4 141 40 21 21 38 46 38 57 0 30 -65 156 -96 185 -16 15 -46 34 -66 42 -46
20 -149 20 -202 0z"
          />
          <path
            d="M5464 2519 c-24 -12 -96 -116 -123 -179 -57 -130 -80 -339 -51 -465
34 -153 145 -245 293 -245 75 0 125 19 181 71 102 93 160 254 160 444 1 131
-22 202 -87 272 -52 57 -113 83 -217 93 -47 5 -96 11 -110 14 -14 3 -34 1 -46
-5z m271 -204 c19 -18 25 -35 25 -70 0 -86 -88 -359 -137 -424 -34 -46 -42
-51 -76 -51 -101 0 -121 226 -35 412 61 134 163 194 223 133z"
          />
          <path
            d="M6030 2516 c-12 -15 -12 -47 -2 -214 16 -261 7 -348 -55 -534 -38
-113 -19 -156 60 -138 21 4 64 6 96 3 33 -3 71 -3 85 1 37 9 43 57 15 126 -19
50 -21 71 -20 229 2 186 10 246 42 297 39 64 120 121 154 108 68 -26 57 -509
-15 -659 -46 -97 -17 -120 144 -112 83 4 102 9 120 26 15 15 18 25 11 35 -48
65 -46 53 -45 274 0 116 3 217 6 226 10 27 -16 196 -38 242 -44 94 -158 122
-270 65 -55 -28 -57 -28 -63 -10 -9 27 -44 39 -135 46 -63 5 -78 3 -90 -11z"
          />
          <path
            d="M6787 2509 c-12 -20 -13 -43 -6 -129 21 -268 7 -443 -46 -600 -37
-108 -32 -153 16 -151 13 1 70 1 126 1 98 0 103 1 119 25 18 27 16 40 -17 130
-20 55 -21 73 -16 230 6 230 29 290 137 361 47 32 65 27 88 -23 14 -31 17 -68
16 -213 -1 -199 -20 -333 -60 -412 -44 -86 -18 -109 124 -107 104 1 130 8 149
35 12 19 11 25 -14 55 l-28 33 0 300 c0 339 -4 364 -65 429 -36 39 -41 41
-107 45 -61 4 -76 1 -124 -24 -30 -16 -57 -30 -61 -32 -5 -2 -8 6 -8 17 0 28
-30 39 -129 47 -76 6 -79 6 -94 -17z"
          />
          <path
            d="M2630 2485 c-12 -15 -12 -21 11 -105 20 -77 5 -403 -26 -557 -23
-110 -20 -148 11 -172 25 -19 35 -21 123 -15 130 9 131 9 102 129 -33 138 -41
425 -16 540 10 44 19 100 19 125 1 38 -3 46 -24 57 -37 19 -184 17 -200 -2z"
          />
          <path
            d="M3625 2491 c-123 -32 -226 -155 -273 -325 -12 -43 -17 -100 -16 -191
0 -112 3 -138 23 -188 29 -73 83 -131 136 -146 22 -6 72 -11 111 -11 82 0 118
19 191 99 72 78 144 248 129 306 -6 23 -54 55 -84 55 -31 0 -59 -44 -67 -103
-18 -134 -82 -217 -165 -217 -57 0 -87 49 -92 150 l-3 55 50 6 c104 13 217 97
262 196 34 74 36 185 4 237 -12 19 -35 45 -51 56 -32 23 -109 33 -155 21z m73
-153 c7 -7 12 -33 12 -58 0 -82 -89 -180 -162 -180 -33 0 -33 1 -9 78 36 118
118 201 159 160z"
          />
          <path
            d="M7738 2484 c-31 -9 -68 -33 -106 -69 -108 -100 -162 -245 -165 -440
-1 -85 3 -111 26 -174 46 -131 114 -176 255 -170 77 4 82 6 129 45 26 22 65
63 85 90 42 55 95 183 100 240 3 33 -1 40 -31 61 -69 47 -104 22 -122 -87 -21
-133 -83 -211 -166 -210 -56 1 -82 41 -90 139 l-6 68 50 5 c155 15 293 163
293 314 0 72 -17 118 -57 156 -52 50 -108 59 -195 32z m103 -161 c15 -39 2
-96 -32 -142 -35 -50 -66 -70 -115 -78 l-37 -6 6 37 c9 54 54 146 90 184 39
40 74 42 88 5z"
          />
          <path
            d="M8354 2476 c-78 -35 -147 -125 -190 -247 -41 -115 -53 -177 -54 -268
0 -192 87 -306 245 -319 75 -6 109 10 158 70 53 66 117 213 117 271 0 52 -60
88 -89 53 -6 -8 -21 -47 -32 -87 -46 -166 -114 -215 -165 -118 -19 38 -19 207
1 284 29 109 95 234 95 178 0 -11 -7 -43 -16 -72 -18 -60 -15 -73 20 -96 31
-21 61 -7 71 32 13 55 58 159 86 200 16 23 29 46 29 52 0 19 -32 42 -51 36
-11 -4 -30 3 -49 16 -37 28 -130 36 -176 15z"
          />
          <path
            d="M2405 1834 c-73 -20 -207 -42 -310 -49 -88 -7 -90 -8 -106 -39 -9
-17 -27 -50 -39 -74 -12 -24 -21 -45 -18 -47 2 -3 118 -7 257 -9 l253 -5 20
47 c25 55 48 137 48 170 0 26 -27 28 -105 6z"
          />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
